<template>
  <div>
    <!-- Title / Edit btn -->
    <div class="positions__header margin margin-bottom margin-medium-large">
      <h5>{{ $t('Components.Controller.positions') }} </h5>
    </div>
    <template>
      <PositionItem
        v-for="(position) of positions"
        :key="position.id"
        :position="position"
        :device="device"
        @replace="updatePosition"
        @editPosition="goToEditPosition"
      />
    </template>
  </div>
</template>

<script>
import ConnectState from '@/singletons/connect.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import UserService from '@/Services/User/user.service';
import CustomEvent from '@/classes/custom.event.class';
import PositionItem from './position.item.vue';

export default {
  components: {
    PositionItem,
  },
  computed: {
    device() { return ConnectState.selectedDevice; },
    positions() { return [UserState.profile.StandHeight, UserState.profile.SitHeight]; },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
  },
  methods: {
    async updatePosition(position) {
      if (position === 'sitting' && this.device.position >= UserState.profile.StandHeight) {
        const evt = new CustomEvent(this.$t('Components.Controller.sit_over_stand'), 'global_error_message', 'danger');
        evt.dispatch();
        return;
      }
      if (position === 'standing' && this.device.position <= UserState.profile.SitHeight) {
        const evt = new CustomEvent(this.$t('Components.Controller.stand_under_sit'), 'global_error_message', 'danger');
        evt.dispatch();
        return;
      }
      if (position === 'standing' && (Math.abs(this.device.position - UserState.profile.SitHeight) <= 50)) {
        const evt = new CustomEvent(this.$t('Components.Controller.positions_are_too_close'), 'global_error_message', 'danger');
        evt.dispatch();
        return;
      }
      if (position === 'sitting' && (Math.abs(this.device.position - UserState.profile.StandHeight) <= 50)) {
        const evt = new CustomEvent(this.$t('Components.Controller.positions_are_too_close'), 'global_error_message', 'danger');
        evt.dispatch();
        return;
      }
      const key = position === 'sitting' ? 'SitHeight' : 'StandHeight';
      await UserService.saveProfile(key, this.device.position);
    },
    goToEditPosition(position) {
      const key = position === 'sitting' ? 'SitHeight' : 'StandHeight';
      const path = `/controller/position/${key}`;
      this.$router.push(path);
    },
  },
};

</script>
<style lang="scss">
@import '../style/controller.scss';
.positions__container {
  height: 100%;
  position: relative;
  padding: 10px;
  padding-bottom: 0px;
}

.positions__header {
  display: flex;
  align-items: center;
  h5 {
    margin: 0;
    flex-grow: 1;
  }
}

</style>
