<template>
  <div>
    <!-- Title / Edit btn -->
    <div class="positions__header margin margin-bottom margin-medium-large">
      <h5>{{ $t('Components.Controller.positions') }} </h5>
    </div>
    <PositionItemFake
      v-for="(position, index) of positions"
      :key="index"
      :position="position"
      @changePosition="changePositionOnFakeDesk"
    />
  </div>
</template>

<script>
import UserState from '@/singletons/user.state.singleton';
import ReminderStateSingleton from '@/singletons/reminder.state.singleton';
import PositionItemFake from './position.item.fake.vue';

export default {
  components: {
    PositionItemFake,
  },
  computed: {
    positions() { return ['standing', 'sitting']; },
    unusedPosition() {
      if (UserState.currentPosition !== 'sitting') {
        return 'sitting';
      }
      return 'standing';
    },
  },
  methods: {
    changePositionOnFakeDesk(position) {
      if (position !== UserState.currentPosition) {
        UserState.currentPosition = this.unusedPosition;
        ReminderStateSingleton.handlePositionChanged();
      }
    },
  },
};

</script>
<style lang="scss">
@import '../style/controller.scss';
.positions__container {
  height: 100%;
  position: relative;
  padding: 10px;
  padding-bottom: 0px;
}

.positions__header {
  display: flex;
  align-items: center;
  h5 {
    margin: 0;
    flex-grow: 1;
  }
}

</style>
