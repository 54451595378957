<template>
  <div class="content__container">
    <!-- Info row -->
    <div class="content__info">
      <Button
        class="desk-settings-btn"
        variant="pastel-primary"
        size="tiny"
        @click="$router.push('/controller/settings')"
      >
        <svg-icon
          class="margin margin-tiny margin-right"
          src="../icons/cog.svg"
        />
        <p class="margin margin-none font small-semi">
          {{ $t('Components.Controller.desk_settings') }}
        </p>
      </Button>
      <h3> {{ currentHeight }} </h3>
    </div>
    <!-- image / heightbar -->
    <div
      class="content__visual"
    >
      <DeskImage
        class="image-top-margin"
        src="images/desk.png"
      />
      <div class="height-bar__container">
        <HeightBar
          :device="device"
          :current-height="device.position"
          :high-limit="device.highLimit"
          :low-limit="device.lowLimit"
          :max-height="device.maxHeight"
          :sit-pos="sitPosition"
          :stand-pos="standPosition"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/button.vue';
import DeskImage from '@/components/desk.image.vue';
import ConnectState from '@/singletons/connect.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import HeightBar from './height.bar.vue';

export default {
  components: {
    DeskImage,
    Button,
    HeightBar,
  },
  data() {
    return {
      ConnectState,
      UserState,
    };
  },
  computed: {
    device() { return ConnectState.selectedDevice; },
    sitPosition() { return UserState.profile.SitHeight; },
    standPosition() { return UserState.profile.StandHeight; },
    devicePosition() {
      const activeState = Math.abs(this.sitPosition - this.device.position) < Math.abs(this.standPosition - this.device.position) ? this.sitPosition : this.standPosition;
      if (activeState === this.sitPosition) {
        return 'sitting';
      }
      return 'standing';
    },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    currentHeight() {
      return `${this.device.getPosition(this.measurementUnit)} ${this.measurementUnit}`;
    },
  },
  watch: {
    'activeDevice.position': function a() {
      if (this.devicePosition !== UserState.currentPosition) {
        UserState.currentPosition = this.devicePosition;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../style/controller.scss';

.content__visual{
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  .image-top-margin{
    margin-top: 3rem;
  }
  .height-bar__container{
    position: absolute;
    right: 0;
    top:0;
    margin-right: 3rem;
    padding: 1rem;
  }
}

.content__info {
  &.reverse{
    flex-direction: row-reverse;
    .position-selection_container{
      display: flex;
      flex-direction: column;
      h3, h6{
        display: flex;
        justify-content: flex-end;
        &.active{
          color: var(--primary-color);
        }
      }
    }
  }
  display: flex;
  justify-content: space-between;
  .btn {
    padding: 0.5rem 0.625rem;
    border-radius: 0.625rem;
    p {
      display: inline-block;
      color: var(--primary-color);
    }
  }
  .flex-col {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  h6 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom:auto;
    display: flex;
    align-items: flex-end;
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-font-color);
    font-variant-numeric: tabular-nums;
  }
}

.desk-settings-btn {
  span.icon {
    svg {
    fill: var(--primary-color);
    height: 1.25rem;
    width: 1.25rem;
    }
  }
  p {
    margin: 0;
  }
}

.caution-prompt-text {
  position: absolute;
  top: 60px;
  right: 1px;
}

.show-caution-prompt-icon {
  visibility: visible;
}

.hide-caution-prompt-icon {
  visibility: hidden;
}

</style>
