<template>
  <div>
    <!-- Connecting -->
    <template v-if="device && !device.ready">
      <Header
        class="flex"
      >
        <h5 class="center-text header-title">
          {{ $t('Components.Controller.connecting') }}
        </h5>
      </Header>
      <Card>
        <Connecting
          :device="device"
        />
      </Card>
    </template>

    <!-- Homing -->
    <template v-else-if="device && device.isHoming">
      <Header
        class="flex"
      >
        <h5 class="center-text header-title">
          {{ $t('Components.WellnessError.homing_error_header') }}
        </h5>
      </Header>
      <Card>
        <HomingError />
      </Card>
    </template>

    <!-- Connection Error -->
    <template v-else-if="device && device.connectionFailed">
      <Header
        class="flex"
      >
        <h5 class="center-text header-title error">
          {{ $t('Components.WellnessError.connection_error_header') }}
        </h5>
      </Header>
      <Card>
        <UnplugError />
      </Card>
    </template>

    <!-- Desk -->
    <template v-else-if="device">
      <div class="desk_content grow flex flex-column">
        <Header
          class="flex"
        >
          <h5 class="center-text header-title">
            {{ $t('Components.Controller.connected_desk') }}
          </h5>
        </Header>

        <!-- Wellness Content -->
        <Card
          id="device-display"
          style="margin-top: 0px;"
        >
          <ControllerContent
            :device="device"
          />
          <ControllerPositions
            class="margin margin-medium-large"
            :device="device"
          />
        </Card>

        <div class="limit-height_container">
          <!-- High limit -->
          <div
            v-show="showHighLimitPrompt === true"
            class="limit-texts"
          >
            <p>{{ $t('Components.Controller.highest_limit_reached') }}</p>
          </div>
          <!-- Low limit -->
          <div
            v-show="showLowLimitPrompt === true"
            class="limit-texts"
          >
            <p>{{ $t('Components.Controller.lowest_limit_reached') }}</p>
          </div>
        </div>

        <!-- Wellness Hand Controller -->
        <Card
          position="bottom no-padding"
          class="flex grow flex-center-h flex-end card no-padding no-border"
        >
          <ControllerHandController
            :device="ConnectState.selectedDevice"
            @mouseoverUp="checkLimits('high')"
            @mouseleaveUp="showHighLimitPrompt = false"
            @mouseoverDown="checkLimits('low')"
            @mouseleaveDown="showLowLimitPrompt = false"
          />
        </Card>
      </div>
    </template>

    <!-- Virtual Desk -->
    <template v-else>
      <div class="desk_content">
        <Header
          class="flex"
        >
          <h5 class="center-text header-title">
            {{ $t('Components.Controller.virtual_desk') }}
          </h5>
        </Header>
      </div>

      <Card>
        <VirtualControllerContent />
        <VirtualControllerPositions
          class="margin margin-medium-large"
        />
      </Card>

      <Card
        position="bottom"
      >
        <SettingsListItem>
          <div class="virtual-desk_info-text">
            <svg-icon src="/icons/information_circle.svg" />
            <p>
              {{ $t('Components.Controller.fake_desk_connected') }}
              <a
                class="virtual-desk_navigate-text"
                @click="navigate"
              >
                {{ $t('Components.Controller.compatible_desk') }}
              </a>
            </p>
          </div>
        </SettingsListItem>
      </Card>
    </template>
  </div>
</template>

<script>
/** Components */
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import SettingsListItem from '@/components/listitem/settings.list.item.vue';
import UnplugError from '@/components/errors/unplug.error.vue';
import HomingError from '@/components/errors/homing.error.vue';
import ControllerContent from './components/controller.content.vue';
import ControllerPositions from './components/controller.positions.vue';
import ControllerHandController from './components/controller.device.controller.vue';
import VirtualControllerContent from './components/virtual.controller.content.vue';
import VirtualControllerPositions from './components/virtual.controller.positions.vue';
import Connecting from './components/connecting.vue';

/** State */
import ConnectState from '../../singletons/connect.state.singleton';

export default {
  components: {
    UnplugError,
    HomingError,
    ControllerContent,
    VirtualControllerContent,
    VirtualControllerPositions,
    Connecting,
    Header,
    ControllerHandController,
    ControllerPositions,
    Card,
    SettingsListItem,
  },
  data() {
    return {
      ConnectState,
      showHighLimitPrompt: false,
      showLowLimitPrompt: false,
    };
  },
  computed: {
    device() { return ConnectState.selectedDevice; },
    isFakeDesk() { return this.device?.type === 'FAKE_DESK'; },
  },
  mounted() {
    const moveToPosition = this.$route?.query?.moveToPosition;
    if (moveToPosition && ConnectState.selectedDevice) {
      this.device.moveToPosition(moveToPosition);
    }
  },
  methods: {
    checkLimits(key) {
      const { position } = ConnectState.selectedDevice;
      const { highLimit } = ConnectState.selectedDevice;
      const { lowLimit } = ConnectState.selectedDevice;
      if (key === 'high') {
        if (Math.abs(position - highLimit) <= 20) {
          this.showHighLimitPrompt = true;
        }
      } else if (key === 'low') {
        if (Math.abs(position - lowLimit) <= 20) {
          this.showLowLimitPrompt = true;
        }
      }
    },
    navigate() {
      this.$router.push('/controller/compatibledesks');
    },
  },
};
</script>

<style lang="scss">
@import './style/controller.scss';
.desk_content{
  overflow-y: auto;
  &.grow{
    height: 100%;
  }
}
.virtual-desk_info-text {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  svg {
    height: 1.25rem;
    width: 1.25rem;
    color: var(--primary-color);
    fill: var(--primary-color);
    }
}
.virtual-desk_navigate-text {
  color: var(--primary-color);
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: 600;
}
.limit-height_container{
  padding: 0.5rem;
  margin: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0;
}
.list-button{
  float: left;
  display: flex;
  gap: .55rem;
  position: absolute;
  margin: 0;
   top: 50%;
  transform: translateY(-50%);
  p{
    padding-top: .08rem;
    color: #666666;
  }
  a{
    display: flex;
    flex-direction: flex-start;
    gap: 0.2rem;
    p{
      margin: auto;
    }
  }
  .icon{
    svg{
      width: .9rem;
      height: .9rem;
      fill: #666666;
    }
  }
}
.limit-texts {
  padding: 5px;
  border-radius: 3px;
  display: flex;
  p {
    margin: auto;
  }
}

.not-connected-text-container {
  background-color: var(--danger-color);
  padding: 1rem 2rem;
  margin: 1rem 3rem;
  border-radius: 3px;
  display: flex;
  p {
    margin: auto;
    color: white;
  }
}
</style>
