<template>
  <div
    class="position__container margin margin-bottom margin-medium"
    :class="{ selected_position_item: currentPosition === position,
              sitting: position.type === 'sitting'}"
  >
    <div
      class="position_information padding padding-small padding-top padding-bottom"
      @click="changePosition"
    >
      <RoundResourceIcon
        type="resource reverse"
        :src="getIcon"
        :class="currentPosition === position ? 'selected_position_item': null"
      />
      <h6>{{ name }}</h6>
    </div>
  </div>
</template>

<script>
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import UserState from '@/singletons/user.state.singleton';

export default {
  components: {
    RoundResourceIcon,
  },
  props: {
    position: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      UserState,
      name: null,
    };
  },
  computed: {
    currentPosition() {
      return UserState.currentPosition;
    },
    getIcon() {
      return this.position === 'standing' ? 'icons/stand.svg' : 'icons/sit.svg';
    },
  },
  mounted() {
    if (this.position === 'sitting') {
      this.name = this.$t('Components.Controller.default_sit_name');
    }
    if (this.position === 'standing') {
      this.name = this.$t('Components.Controller.default_stand_name');
    }
  },
  methods: {
    changePosition() {
      this.$emit('changePosition', this.position);
    },
  },
};
</script>

<style lang="scss" >
.position__container{
  display: flex;
  justify-content: space-between;
  .position_information{
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 1rem;
    p, h6{
      text-align: center;
      align-self: center;
      color: var(--primary-font-color);
      margin: 0;
    }
  }
  .position_data{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &.selected_position_item {
    background: var(--primary-color-light);
    border-radius: 0.5rem;
  }
  &.sitting {
    margin-bottom: 0;
  }
}
.selected_position_item {
    background: var(--primary-color-light) !important;
}
</style>
