<template>
  <div
    class="position__container margin margin-bottom margin-medium-large"
    :class="currentMode === 'stand' ? 'disabled': null"
  >
    <div
      class="position_information"
      @mouseup="safeControl ? device.stop() : () => {}"
      @mouseleave="safeControl ? device.stop() : () => {}"
      @mousedown="moveDesk()"
    >
      <RoundResourceIcon
        type="resource reverse"
        :src="getIcon"
      />
      <div class="position_data">
        <h6>{{ positionName }}</h6>
        <p>
          {{ height }}
        </p>
      </div>
    </div>
    <div
      v-if="!showStopButton"
      class="kebab-menu"
    >
      <Kebab :options="getKebabOptions()" />
    </div>
    <div v-else>
      <div
        class="stop-container"
        @click="device.stop()"
      >
        <BIcon
          class="stop-small"
          icon="square-fill"
        />
        <p class="tiny-semi">
          {{ $t("Components.Controller.stop") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue';
import Kebab from '@/components/kebab.menu.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import { mmToUnitParsed } from '@/functions/unit.converter.functions';
import UserState from '@/singletons/user.state.singleton';

export default {
  components: {
    Kebab,
    BIcon,
    RoundResourceIcon,
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // name: null,
      // positionName: null,
    };
  },
  computed: {
    currentMode() {
      return 'stand';
    },
    showStopButton() {
      if (this.device.isAutoMoving === this.position) {
        return true;
      }
      return false;
    },
    getIcon() {
      return this.positionValue === 'standing' ? 'icons/stand.svg' : 'icons/sit.svg';
    },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    height() {
      return `${mmToUnitParsed(this.position, this.device.offset)} ${this.measurementUnit}`;
    },
    safeControl() { return !!UserState.profile.safecontrol; },
    positionName() {
      if (UserState.profile.SitHeight === this.position) {
        return this.$t('Components.Controller.default_sit_name');
      }
      return this.$t('Components.Controller.default_stand_name');
    },
    positionValue() {
      if (UserState.profile.SitHeight === this.position) {
        return 'sitting';
      }
      return 'standing';
    },

  },
  methods: {
    moveDesk() {
      this.device.moveToPosition(this.position);
    },
    getKebabOptions() {
      return [
        { text: this.$t('Components.Controller.replace_height'), isDanger: false, action: () => { this.$emit('replace', this.positionValue); } },
      ];
    },
  },
};
</script>

<style lang="scss" >
.position__container{
  display: flex;
  justify-content: space-between;
  .position_information{
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    gap: 1rem;
    p, h6{
      color: var(--primary-font-color);
      margin: 0;
    }
  }
  .position_data{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .kebab-menu{
    display: flex;
    margin: auto 0;
  }
  .stop-container{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    background-color: var(--danger);
    border-radius: 0.2rem;
    padding: 0.4rem 0.7rem;
    p{
      margin: auto 0;
      color: white;
    }
    svg {
      margin: auto 0;
      background-color: white;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.1rem;
    }
  }
}
</style>
