<template>
  <div class="content__container">
    <div class="content__info reverse">
      <div class="position-selection_container">
        <h6>
          {{ $t('Components.Controller.current_position') }}
        </h6>
        <h3 class="position_text">
          {{ positionName }}
        </h3>
      </div>
    </div>
    <!-- image -->
    <div class="content__visual">
      <DeskImage
        class="image-top-margin"
        src="images/desk.png"
      />
    </div>
  </div>
</template>

<script>
import DeskImage from '@/components/desk.image.vue';
import UserState from '@/singletons/user.state.singleton';
import ConnectState from '@/singletons/connect.state.singleton';

export default {
  components: {
    DeskImage,
  },
  data() {
    return {
      UserState,
      ConnectState,
    };
  },
  computed: {
    positionName() {
      if (UserState.currentPosition === 'sitting') {
        return this.$t('Components.Controller.default_sit_name');
      }
      return this.$t('Components.Controller.default_stand_name');
    },
    unusedPositionName() {
      if (UserState.currentPosition !== 'sitting') {
        return this.$t('Components.Controller.default_sit_name');
      }
      return this.$t('Components.Controller.default_stand_name');
    },
  },
};
</script>

<style lang="scss">
@import '../style/controller.scss';

.content__visual{
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  .image-top-margin{
    margin-top: 3rem;
  }
  .height-bar__container{
    position: absolute;
    right: 0;
    top:0;
    margin-right: 3rem;
    padding: 1rem;
  }
}

.content__info {
  &.reverse{
    flex-direction: row-reverse;
    .position-selection_container{
      display: flex;
      flex-direction: column;
      h3, h6{
        display: flex;
        justify-content: flex-end;
        &.active{
          color: var(--primary-color);
        }
      }
    }
  }
  display: flex;
  justify-content: space-between;
  .btn {
    padding: 0.5rem 0.625rem;
    border-radius: 0.625rem;
    p {
      display: inline-block;
      color: var(--primary-color);
    }
  }
  .flex-col {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  h6 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom:auto;
    display: flex;
    align-items: flex-end;
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-font-color);
    font-variant-numeric: tabular-nums;
  }
}

.desk-settings-btn {
  span.icon {
    svg {
    fill: var(--primary-color);
    height: 1.25rem;
    width: 1.25rem;
    }
  }
}

.caution-prompt-text {
  position: absolute;
  top: 60px;
  right: 1px;
}

.show-caution-prompt-icon {
  visibility: visible;
}

.hide-caution-prompt-icon {
  visibility: hidden;
}

</style>
